import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { AnimatePresence, motion } from 'framer-motion'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

// Images
import TitleDefault from 'components/elements/Text/TitleDefault'
import ButtonSecondaryDark from 'components/elements/Buttons/ButtonSecondaryDark'
import Cancel from 'img/cancel.inline.svg'
import ParseContent from 'components/shared/ParseContent'

const NotFound = styled.h3`
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const FlexibleText = styled(ParseContent)`
  & p {
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

interface ProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Projects: React.FC<ProjectsProps> = ({ fields }) => {
  const {
    allWpProject,
    allWpProjectcategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.projectsQueryQuery>(graphql`
    query projectsQuery {
      allWpProject(sort: { order: ASC, fields: date }) {
        edges {
          node {
            title
            uri
            projectdetail {
              mainimage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 1440)
                  }
                }
              }
              status
            }
            projectcategories {
              nodes {
                id
                name
              }
            }
          }
        }
      }
      allWpProjectcategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const filteredPosts: Array<any> = []

  const posts: any = allWpProject.edges

  if (fields.projectcategory === 'inDevelopment') {
    posts.forEach((post: any) => {
      if (post.node.projectdetail.status === 'inDevelopment') {
        filteredPosts.push(post)
      }
    })
  }

  if (fields.projectcategory === 'realised') {
    posts.forEach((post: any) => {
      if (post.node.projectdetail.status === 'realised') {
        filteredPosts.push(post)
      }
    })
  }

  const blogBinkFilterHek: string = `projects-${fields.projectcategory}`

  return (
    <section className="mt-lg-5">
      <BlogBink
        posts={filteredPosts as BlogBinkPosts}
        // --------   TIJDELIJK UITGECOMMENT -> Op aanvraag aanzetten   --------
        // categories={allWpProjectcategory.edges}
        // categoryName="projectcategories"
        id={blogBinkFilterHek}
        limit={Number(fields.limit)}
      >
        <BlogContent fields={fields} />
        <BlogPosts fields={fields} blogBinkFilterHek={blogBinkFilterHek} />
      </BlogBink>
    </section>
  )
}

interface BlogPostsProps extends ProjectsProps {
  blogBinkFilterHek: string
}

const BlogPosts: React.FC<BlogPostsProps> = ({ fields, blogBinkFilterHek }) => {
  const blogBink = useBlogBink()

  // @ts-ignore
  const { selectedFilters } = blogBink
  const isOpen: boolean = selectedFilters.length === 1

  return (
    <AnimatePresence>
      <motion.div
        initial="collapsed"
        animate={isOpen ? 'open' : 'close'}
        exit="close"
        variants={{
          open: {
            background: 'rgba(143, 161, 187, 1)',
            transition: {
              duration: 1.4,
              ease: [0.04, 0.62, 0.23, 0.98],
            },
          },
          close: {
            background: 'rgba(143, 161, 187, 0)',
            transition: {
              duration: 1.4,
              ease: [0.04, 0.62, 0.23, 0.98],
            },
          },
        }}
      >
        <InnerSection isFiltered={selectedFilters.length === 1}>
          <div className="py-5">
            {fields.projectcategory === 'inDevelopment' ? (
              <TitleDefault isCentered className="text-center mb-5">
                <h2>IN ONTWIKKELING</h2>
              </TitleDefault>
            ) : (
              <TitleDefault isCentered className="text-center mb-5">
                <h2>GEREALISEERDE PROJECTEN</h2>
              </TitleDefault>
            )}
            {/* --------   TIJDELIJK UITGECOMMENT -> Op aanvraag aanzetten   -------- */}
            {/* <BlogFilters blogBinkFilterHek={blogBinkFilterHek} /> */}
            <BlogGrid fields={fields} />
          </div>
        </InnerSection>
      </motion.div>
    </AnimatePresence>
  )
}

const BlogContent: React.FC<ProjectsProps> = ({ fields }) => {
  // @ts-ignore
  const { selectedFilters } = useBlogBink()
  const currentFilterId = selectedFilters[0]
  const isOpen: boolean = selectedFilters.length === 1

  return (
    <div className="container">
      {/* --------   TIJDELIJK UITGECOMMENT -> Op aanvraag aanzetten   -------- */}

      {/* <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{
              opacity: 0,
              height: 0,
              overflow: 'hidden',
            }}
            exit={{
              opacity: 0,
              height: 0,
              overflow: 'hidden',
            }}
            animate={{
              opacity: 1,
              height: 'fit-content',
              overflow: 'hidden',
              transition: {
                duration: 1.2,
                ease: [0.04, 0.62, 0.23, 0.98],
              },
            }}
          > */}
      <div className="row justify-content-center py-md-5 mb-5">
        <div className="col-lg-8">
          <div className="row">
            <div className="col-xl-4">
              <TitleDefault className="mb-5">
                <ParseContent
                  content={
                    fields.flexibletext?.title1
                    // --------   TIJDELIJK UITGECOMMENT -> Op aanvraag aanzetten   --------
                    // currentFilterId === 'dGVybTo3MQ=='
                    //   ? fields.flexibletext?.title1
                    //   : fields.flexibletext?.title2
                  }
                />
              </TitleDefault>
            </div>
            <div className="col-xl-8">
              <FlexibleText
                content={
                  fields.flexibletext?.description1
                  // --------   TIJDELIJK UITGECOMMENT -> Op aanvraag aanzetten   --------
                  // currentFilterId === 'dGVybTo3MQ=='
                  //   ? fields.flexibletext?.description1
                  //   : fields.flexibletext?.description2
                }
              />
            </div>
          </div>

          {/* --------   TIJDELIJK ELEMENT -> Op aanvraag verwijderen   -------- */}
          <div className="row mt-5">
            <div className="col-xl-4">
              <TitleDefault className="mb-5">
                <ParseContent content={fields.flexibletext?.title2} />
              </TitleDefault>
            </div>
            <div className="col-xl-8">
              <FlexibleText content={fields.flexibletext?.description2} />
            </div>
          </div>
        </div>
      </div>

      {/* --------   TIJDELIJK UITGECOMMENT -> Op aanvraag aanzetten   -------- */}
      {/* </motion.div>
        )}
      </AnimatePresence> */}
    </div>
  )
}

// --------   TIJDELIJK UITGECOMMENT -> Op aanvraag aanzetten   --------

// const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
//   background-color: ${(props) =>
//     props.selected ? props.theme.color.secondary : props.theme.color.light};
//   color: ${(props) =>
//     props.selected ? props.theme.color.light : props.theme.color.primary};
//   border: ${(props) =>
//     !props.selected
//       ? `1px solid ${props.theme.color.primary}`
//       : '1px solid transparent'};
//   transition: all 0.6s;

//   &:hover {
//     background-color: ${({ theme }) => theme.color.secondary};
//     border: 1px solid ${({ theme }) => theme.color.secondary};
//     color: ${({ theme }) => theme.color.light};
//   }
// `

// const CancelWrapper = styled.div`
//   right: -9px;
//   top: -13px;
//   & svg {
//     width: 16px;
//     height: 16px;
//   }
// `

// interface BlogFiltersProps {
//   blogBinkFilterHek: string
// }

// interface BlogFilterProps {
//   node: {
//     id: string
//     name: string
//   }
// }

// const BlogFilters: React.FC<BlogFiltersProps> = ({ blogBinkFilterHek }) => {
//   const blogBink = useBlogBink()

//   let navigatedCategory = ''

//   if (typeof window !== `undefined` && window.location.hash) {
//     navigatedCategory = window.location.hash.replace('#', '')
//   }

//   React.useEffect(() => {
//     if (navigatedCategory !== '') {
//       const selectedCategory = blogBink.categories.find(
//         ({ node }: any) => node.name.toLowerCase() === navigatedCategory
//       )

//       if (selectedCategory) {
//         localStorage.removeItem(`${blogBinkFilterHek}-filters`)
//         blogBink.setSelectedFilters(selectedCategory.node.id)
//       }
//     }
//   }, [navigatedCategory])

//   return (
//     <div className="container d-flex flex-column flex-sm-row justify-content-center ">
//       {blogBink.categories.map((category: BlogFilterProps) => {
//         const selected: boolean = blogBink.isSelected(category.node.id)

//         return (
//           <StyledBlogFilter
//             key={category.node.id}
//             id={category.node.id}
//             selected={selected}
//             className="mr-3 px-3 mx-2 mb-3 mb-sm-0 position-relative text-uppercase"
//           >
//             {selected && (
//               <CancelWrapper className="position-absolute">
//                 <Cancel />
//                 <div
//                   style={{
//                     width: 12,
//                     height: 12,
//                     borderRadius: '50%',
//                     marginTop: '-17px',
//                     backgroundColor: '#FFF',
//                   }}
//                 />
//               </CancelWrapper>
//             )}

//             <span>{category.node.name}</span>
//           </StyledBlogFilter>
//         )
//       })}
//     </div>
//   )
// }

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InnerSection = styled.section<{ isFiltered: boolean }>`
  /* background: ${(props) =>
    props.isFiltered ? props.theme.color.background : 'transparent'}; */
  padding: 2rem;
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container mt-5">
      <Row className="row">
        {blogBink.posts.length > 0 &&
          blogBink.posts.map((post) => {
            const { node }: any = post

            return (
              <Column key={post.node.id} className="col-sm-6 col-md-4 mb-5">
                <BlogGridPost node={node} blogFields={fields} />
              </Column>
            )
          })}
        {blogBink.posts.length < 1 && (
          <NotFound className="text-center mt-5">
            Helaas, binnen deze categorie hebben we (nog) geen projecten.
            Probeer een andere categorie.
          </NotFound>
        )}
      </Row>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

const StyledPost = styled.div`
  display: flex;
  flex-direction: column;
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    // eslint-disable-next-line
    projectdetail: GatsbyTypes.WpProject_Projectdetail
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => (
  <StyledPost className="d-flex flex-column justify-content-between h-100">
    <div>
      <Plaatjie
        image={node.projectdetail.mainimage}
        alt={node.title}
        style={{ maxHeight: 285, minHeight: 285 }}
      />
      <h6 className="text-uppercase mt-2">{node.title}</h6>
    </div>

    <div>
      <ButtonSecondaryDark to={node.uri} className="mt-3">
        {blogFields.readmoretext}
      </ButtonSecondaryDark>
    </div>
  </StyledPost>
)

export default Projects
